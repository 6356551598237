import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../Fragments/metaFragments";

export const ALLUSERLISTPRODUCT = gql`
  query userListProduct(
    $offset: Int!
    $limit: Int!
    $query: String
    $sortColumn: String
    $sortOrder: String
    $subCategoryUuid: String
    $topProduct: TopProductEditEnum
  ) {
    userListProduct(
      input: {
        offset: $offset
        limit: $limit
        query: $query
        sort_column: $sortColumn
        sort_order: $sortOrder
        sub_category_uuid: $subCategoryUuid
        top_product: $topProduct
      }
    ) {
      meta {
        ...MetaFragment
      }
      data {
        uuid
        category_name
        sub_category_name
        name
        part_number
        outer_td
        outer_bd
        inner_td
        inner_bd
        no_check_valve
        no_pass_valves
        thread
        height
        main_img
        sec_img
        thrd_img
        price
        status
      }
    }
  }
  ${META_FRAGMENT}
`;

export const VIEWPRODUCT = gql`
query userViewProduct(
    $uuid: String!
  ) {
    userViewProduct(
      input: {
        uuid: $uuid
      }
    ) {
      meta {
        ...MetaFragment
      }
      data {
        uuid
        category_name
        sub_category_name
        name
        part_number
        outer_td
        outer_bd
        inner_td
        inner_bd
        no_check_valve
        no_pass_valves
        thread
        height
        main_img
        sec_img
        thrd_img
        price
        status
      }
    }
  }
  ${META_FRAGMENT}
`;