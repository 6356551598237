import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../Fragments/metaFragments";

export const ALLUSERCATEGORYLIST = gql`
  query userListCategory (
    $offset: Int!
    $limit: Int!
    $query: String
    $sortColumn: String
    $sortOrder: String
    ){
    userListCategory(
      input: {
        offset: $offset
        limit: $limit
        query: $query
        sort_column: $sortColumn
        sort_order: $sortOrder
      }
    ) {
      meta {
        ...MetaFragment
      }
      data {
        id
        uuid
        name
        slug
        image
        status
      }
    }
  }
${META_FRAGMENT}
`;