import { Link, useParams } from "react-router-dom";
import { ALLUSERLISTPRODUCT } from "../framework/Graphql/Query/Product";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import alt_img from "../assets/alt_image.png";
import Loader from "./Loading/Loader";

const ProductList = () => {
  const params = useParams();
  const id: any = params.subCatId;
  const [allProduct, setAllProduct] = useState([]);
  const [GetAllProduct, { loading, data }] = useLazyQuery(ALLUSERLISTPRODUCT);
  const getData = () => {
    GetAllProduct({
      variables: {
        offset: 1,
        limit: 100,
        query: "",
        sortColumn: "id",
        sortOrder: "ASC",
        subCategoryUuid: id,
      },
    }).then((res) => {
      setAllProduct(res?.data?.userListProduct?.data);
    });
  };
  useEffect(() => {
    getData();
  }, [id, data]);
  const setAltImages = (e: any) => {
    const getElementID = e.target.id;
    const getElement: any = document.getElementById(getElementID);
    if (getElement.src) {
      return;
    }
    getElement.src = alt_img;
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="untree_co-section product-section before-footer-section">
      <div className="container">
        <div className="row">
          {allProduct &&
            allProduct.length > 0 &&
            allProduct.map((item: any) => {
              return (
                <div className="col-12 col-md-4 col-lg-3 mb-5" key={item.uuid}>
                  <Link
                    className="product-item"
                    to={`/product-details/${item.uuid}`}
                  >
                    <img
                      src={item.main_img}
                      className="img-fluid product-thumbnail"
                      alt={item?.part_number}
                      id="product-list"
                      onError={(e) => {
                        setAltImages(e);
                      }}
                    />
                    <h3 className="product-title">{item.name}</h3>
                    <strong className="product-price">
                      {item.part_number}
                    </strong>

                    <span className="icon-cross">
                      <img
                        src="/images/cross.svg"
                        className="img-fluid"
                        alt="product"
                      />
                    </span>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default ProductList;
