import Footer from "../components/Footer";
import Header from "../components/Header";
import OneProductDetails from "../components/ProductDetails/OneProductDetails";
import ProductHeroSection from "../components/ProductHeroSection";

const ProductDetails = ()=>{
    return(
        <>
            <Header />
            <ProductHeroSection />
            <OneProductDetails/>
            <Footer />
        </>
    );
}
export default ProductDetails;