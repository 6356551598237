import sales from '../assets/sales.png';
import { setAltImage } from '../constant/constant';
const ProductHeroSection = ()=>{
    return(
        <div className="hero">
		<div className="container">
			<div className="row justify-content-between">
				<div className="col-lg-5">
					<div className="intro-excerpt">
						<h1>Products</h1>
						<p className="mb-4">At Airwave Industrial Solutions, we understand that every industrial operation is unique, and that's why we offer an extensive range of high-quality screw compressor parts to meet your specific needs. Whether you're looking for routine maintenance components or critical replacement parts, we've got you covered.</p>
						{/* <p><a href="/#contact" className="btn btn-white-outline">Contact us</a></p> */}
					</div>
				</div>
				<div className="col-lg-7">
					<div className="hero-img-wrap">
						<img src={sales} className="img-fluid" onError={(e)=>{setAltImage(e)}} alt="Product"/>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
}
export default ProductHeroSection;