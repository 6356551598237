import Footer from "../components/Footer";
import Header from "../components/Header";
import SubCategorySection from "../components/SubCategorySection";

const SubCategory = ()=>{ 
    return(
        <>
            <Header />
            <SubCategorySection />
            <Footer />
        </>
    )
}
export default SubCategory;