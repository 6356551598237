import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ALLUSERSUBCATEGORYLIST } from "../framework/Graphql/Query/SubCategory";
import Loader from "./Loading/Loader";
import ProductHeroSection from "./ProductHeroSection";

const SubCategorySection = () =>{
    const params = useParams();
    const id: any = params.catId;
    const [allSubCategory,setAllSubCategory] = useState([]);
    const [GetAllsubCategory, { loading, data }] = useLazyQuery(ALLUSERSUBCATEGORYLIST);
   useEffect(()=>{
    GetAllsubCategory({
        variables:{
          offset: 1,
          limit: 100,
          query: '',
          sortColumn: "id",
          sortOrder: "ASC",
          categoryUuid: id
        }
      }).then((res)=>{
        setAllSubCategory(res?.data?.userListSubCategory?.data);
    });
  },[id, data, GetAllsubCategory]);
    if(loading){
        return (
            <Loader />
        )
    }
    return (
        <>
        <ProductHeroSection />
        <div className="untree_co-section product-section before-footer-section">
		<div className="container">
			<div className="row">
                {
                    allSubCategory && allSubCategory.length > 0 && allSubCategory.map((item:any) => { 
                        return (
                            <div className="col-12 col-md-4 col-lg-4 mb-5">
                            <Link className="product-item" to={`/product/${item?.uuid}`} style={{"boxShadow": "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px", "padding":"25px", "borderRadius": "10px"}}>
                                <h3 className="product-title"><strong>{item.name}</strong></h3>
                                <span className="icon-cross">
                                    <img src="/images/cross.svg" className="img-fluid" alt="Categoru" />
                                </span>
                            </Link>
                        </div>
                        )
                    })
                }
                        {/* <!-- Start Column 1 -->
                        <div className="col-12 col-md-4 col-lg-4 mb-5">
                            <a className="product-item" href="shop.php?cat=<?php echo $itemData['url']; ?>" style="box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; padding:25px; border-radius: 10px;">
                                <h3 className="product-title"><strong><?php echo($itemData['title']) ?></strong></h3>
                                <span className="icon-cross">
                                    <img src="images/cross.svg" className="img-fluid">
                                </span>
                            </a>
                        </div>
                        <!-- End Column 1 --> */}
			</div>
		</div>
	</div>

        </>
    )
}
export default SubCategorySection;