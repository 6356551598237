const Header = () => {
  return (
    <nav
      className="custom-navbar navbar navbar navbar-expand-md navbar-light bg-dark"
      arial-label="Furni navigation bar"
    >
      <div className="container">
        {/* <!-- <a className="navbar-brand" href="index.html">Airwave</a> --> */}
        <a className="navbar-brand" href="/">
          <img src="/images/logo.png" className="logo" alt="Airwave" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsFurni"
          aria-controls="navbarsFurni"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsFurni">
          <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
            <li className="nav-item active">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#product">
                Product
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#about">
                About us
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#blog">
                Blog
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#contact">
                Contact us
              </a>
            </li>
          </ul>

          {/* <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
            <!-- <li><a className="nav-link" href="#"><img src="images/user.svg"></a></li> -->
            <li>
              <a className="nav-link" href="/qutation">
                <img
                  src="/images/product-cart.png"
                  style={{ height: "30px" }}
                  alt="Get Quotation"
                />
                Get Quotation
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
};
export default Header;
