const HeroSection = () => {
  return (
    // <!-- Start Hero Section -->
    <div className="hero">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="intro-excerpt">
              <h1>
                Precision Parts for{" "}
                <span className="d-block">Perfect Performance</span>
              </h1>
              <p className="mb-4">
                Air-wave Industrial Solution is a supplier of replacement or
                equivalent spare parts for Rotary Screw compressors. These parts
                are suitable for renowned brands such as Atlas Copco, ELGI,
                Ingersoll Rand, Chicago Pneumatic, and Kaeser. If you're looking
                for a quotation for equivalent spare parts for your Screw
                Compressor, you can now shop at Air-wave Industrial Solution.
              </p>
              <p>
                <a href="/#product" className="btn btn-secondary me-2">
                  Shop Now
                </a>
                <a href="/#contact" className="btn btn-white-outline">
                  Contact us
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-img-wrap">
              <img
                src="/images/groupproduct2.png"
                className="img-fluid"
                alt="GroupProductImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
