const HelpSection = () => {
  return (
    <div className="we-help-section">
      <div className="container">
        <div className="row d-flex flex-row-reverse justify-content-between">
          <div className="col-lg-7 mb-5 mb-lg-0">
            <div className="imgs-grid">
              <div className="grid grid-1">
                <img src="images/new-img-grid-1.jpg" alt="img" />
              </div>
              <div className="grid grid-2">
                <img src="images/new-img-grid-2.jpg" alt="img" />
              </div>
              <div className="grid grid-3">
                <img src="images/new-img-grid-3.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 ps-lg-5">
            <h2 className="section-title mb-4">
              We Help You Select Right Product
            </h2>
            <p>
              Selecting the right product for screw compressor parts is
              essential to ensure the efficient and reliable operation of your
              compressor system. Here is a general guideline for selecting the
              right screw compressor parts
            </p>
            <ul className="list-unstyled custom-list my-4">
              <li>Identify Your Compressor Model</li>
              <li>Consult the Manufacturer</li>
              <li>Determine the Part You Need</li>
              <li>Consider OEM vs. Aftermarket Parts</li>
              <li>Inspect and Measure</li>
              <li>Seek Professional Advice</li>
            </ul>
            <p>
              Remember that proper maintenance and using the right parts are
              essential for the longevity and efficiency of your screw
              compressor. When in doubt, prioritize safety and consult with
              experts or the manufacturer to avoid potential issues.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HelpSection;
