const ServeIndustries = () => {
  return (
    <div className="blog-section" id="blog">
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-6 mx-auto text-center">
          <h2 className="section-title mb-3">Industries We Serve</h2>
        </div>
      </div>
      <div className="d-flex gap-3 flex-row flex-wrap justify-content-around align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src="images/pharma.png" alt="" />
          <strong className="product-price">Pharmaceatical</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src="images/life-sci.png" alt="" />
          <strong className="product-price">Life Science</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src="images/medical.png" alt="" />
          <strong className="product-price">Medical</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src="images/food.png" alt="" />
          <strong className="product-price">Food & Beverage</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src="images/industrial.png" alt="" />
          <strong className="product-price">Industrial</strong>
        </div>
      </div>
    </div>
  </div>
  );
};
export default ServeIndustries;
