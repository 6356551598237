import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ALLUSERCATEGORYLIST } from "../framework/Graphql/Query/Category";
import { Link } from "react-router-dom";
import Loader from "./Loading/Loader";

const CategorySection = () => {
  const [allCategory,setAllCategory] = useState([]);
  const [GetAllCategory, { loading, data }] = useLazyQuery(ALLUSERCATEGORYLIST,{
    variables:{
      offset: 1,
      limit: 100,
      query: "",
      sortColumn: "id",
      sortOrder: "ASC"
    }
  });
   useEffect(()=>{
    GetAllCategory().then((res)=>{
      setAllCategory(res?.data?.userListCategory?.data);
    });
  },[GetAllCategory, data])
  if(loading){
    return (  
        <Loader />
    )
  }
  return (
    <div className="product-section" id="product">
      <div className="container">
        <div className="row">
          {/* <!-- Start Column 1 --> */}
            <div className="col-md-4 col-12">
              <h2 className="mb-4 section-title">
                Best Quality Replacement Parts.
              </h2>
              <p className="mb-4">
                At Airwave Industrial Solution, we understand the paramount
                importance of using the best quality replacement parts. Our
                commitment to excellence and dedication to customer satisfaction
                drive us to provide you with top-notch replacement components that
                exceed industry standards.
              </p>
            </div>
          {/* <!-- End Column 1 --> */}

          {/* <!-- Start Column 2 --> */}
          <div className="col-md-8 col-12">
            <div className="row">
             {
              allCategory && allCategory.length > 0 && allCategory.map((item:any) => { 
              return(
              <div className="col col-lg-4 col-md-4 col-4" key={item.uuid}>
                <Link className="product-item" to={`/subCategory/${item?.uuid}`}>
                  <img
                    src={item?.image}
                    className="img-fluid product-thumbnail"
                    alt="Category"
                  />
                  <h3 className="product-title">Replacement Parts Of</h3>
                  <strong className="product-price">{item?.name}</strong>
    
                  <span className="icon-cross">
                    <img src="images/cross.svg" className="img-fluid" alt="Category"/>
                  </span>
                  </Link>
              </div>
              );
            })
          }
            </div>
          </div>
          {/* <!-- End Column 2 --> */}
        </div>
      </div>
    </div>
  );
};
export default CategorySection;
