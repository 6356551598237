import React from "react";
import Home from "./pages/Home";
import { Link, Route, Routes } from "react-router-dom";
import BlogDetails from "./components/BlogDetails";
import SubCategory from "./pages/SubCategory";
import Product from "./pages/Product";
import ProductDetails from "./pages/ProductDetails";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/subCategory/:catId" element={<SubCategory />} />
        <Route path="/blogDetails/:blog" element={<BlogDetails />} />
        <Route path="/product/:subCatId" element={<Product />} />
        <Route path="/product-details/:prodUuid" element={<ProductDetails />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <Link
        to="https://wa.me/9558456773"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </Link>
    </div>
  );
}

export default App;
