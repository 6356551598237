import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {

  //Set the custom header
  const customHeaders = {
    "Content-Type": "application/json",
  };

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...customHeaders,
      ...headers,
    },
  }));

  return forward(operation);
});


const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      if (message === "unauthenticated.") {
        window.location.replace("/");
      } else if (extensions.category === "validation") {
        for (const key in extensions?.validation) {
          key && toast.error(extensions?.validation[key][0]);
        }
      } else if (extensions?.meta?.message_code === "UNAUTHORIZED_ACCESS") {
        toast.error(extensions?.meta?.message, {
          onClose: () => window.location.replace("/"),
        });
      } else {
        toast.error(message);
      }
    });
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink]),
});
