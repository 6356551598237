import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <div className="blog-section" id="blog">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 mx-auto text-center">
            <h2 className="section-title">Recent Blog</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
            <div className="post-entry">
              <Link to="blogDetails/1" className="post-thumbnail">
                <img
                  src="images/blog-3.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </Link>
              <div className="post-content-entry">
                <h3>
                  <Link to="blogDetails/1">
                  <strong className="product-price">Importance of Maintenance of Screw Compressor</strong>
                  </Link>
                </h3>
                <div className="meta">
                  <span>
                    Public by <Link to="blogDetails/1">Airwave Industrial Solutions</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
            <div className="post-entry">
              <Link to="blogDetails/2" className="post-thumbnail">
                <img
                  src="images/blog-2.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </Link>
              <div className="post-content-entry">
                <h3>
                  <Link to="blogDetails/2">
                  <strong className="product-price">Maximizing Output, Minimizing Costs: The Central Role of
                    Intake Valves in Screw Compressors</strong>
                  </Link>
                </h3>
                <div className="meta">
                  <span>
                    Public by <Link to="blogDetails/2">Airwave Industrial Solutions</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 mb-4 mb-md-0">
            <div className="post-entry">
              <Link to="blogDetails/3" className="post-thumbnail">
                <img
                  src="images/blog-1.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </Link>
              <div className="post-content-entry">
                <h3>
                  <Link to="blogDetails/3">
                  <strong className="product-price">How to Install Parts in Screw Compressor?</strong>
                  </Link>
                </h3>
                <div className="meta">
                  <span>
                    Public by <Link to="blogDetails/3">Airwave Industrial Solutions</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog;
