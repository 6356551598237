import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import ContactSection from "../components/ContactSection";
import WhyChoose from "../components/WhyChoose";
import HelpSection from "../components/HelpSection";
import TopProduct from "../components/TopProduct";
import Blog from "../components/Blog";
import CategorySection from "../components/Category";
import ServeIndustries from "../components/ServeIndustries";

const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <CategorySection />
      <WhyChoose />
      <HelpSection />
      <ServeIndustries />
      <TopProduct />
      <Blog />
      <ContactSection />
      <Footer />
    </>
  );
};
export default Home;
