const WhyChoose = () => {
  return (
    // < !--Start Why Choose Us Section-- >
    <div className="why-choose-section" id="about">
      <div className="container">
        <div className="row gap-5 justify-content-between">
          <div className="col-lg-6">
            <h2 className="section-title">Why Choose Us</h2>
            <p>
              At Airwave Industrial Solution, we understand that when it comes
              to sourcing industrial screw compressor parts, you have choices.
              We believe that our commitment to excellence and unwavering
              dedication to customer satisfaction set us apart from the
              competition. Here's why you should choose us as your trusted
              partner:
            </p>

            <div className="row my-5">
              <div className="col-6 col-md-6">
                <div className="feature">
                  <div className="icon">
                    <img
                      src="images/expert.png"
                      alt="expertImage"
                      className="imf-fluid"
                    />
                  </div>
                  <h3>Extensive Expertise</h3>
                  <p>
                    With years of experience in the industry, we possess
                    in-depth knowledge of industrial screw compressor parts. Our
                    team's expertise ensures that you receive accurate,
                    reliable, and high-quality components tailored to your
                    specific needs.
                  </p>
                </div>
              </div>

              <div className="col-6 col-md-6">
                <div className="feature">
                  <div className="icon">
                    <img
                      src="images/product-cart.png"
                      alt="Product Cart"
                      className="imf-fluid"
                    />
                  </div>
                  <h3>Wide Range of Products</h3>
                  <p>
                    We maintain a comprehensive inventory of screw compressor
                    parts, compatible with various makes and models. This
                    extensive selection simplifies your sourcing process and
                    allows us to be your one-stop solution for all your
                    compressor part requirements.
                  </p>
                </div>
              </div>

              <div className="col-6 col-md-6">
                <div className="feature">
                  <div className="icon">
                    <img
                      src="images/shield.png"
                      alt="Shield"
                      className="imf-fluid"
                    />
                  </div>
                  <h3>Quality Assurance</h3>
                  <p>
                    Quality is our utmost priority. Every part we supply
                    undergoes rigorous quality control checks to ensure
                    precision and durability. You can trust that our products
                    are built to perform and withstand the demands of your
                    industrial applications.
                  </p>
                </div>
              </div>

              <div className="col-6 col-md-6">
                <div className="feature">
                  <div className="icon">
                    <img
                      src="images/price.png"
                      alt="Price"
                      className="imf-fluid"
                    />
                  </div>
                  <h3>Competitive Pricing</h3>
                  <p>
                    We offer competitive pricing without compromising on
                    quality. Our commitment to affordability ensures you get the
                    best value for your investment, helping you manage your
                    operational costs effectively.
                  </p>
                </div>
              </div>

              <div className="col-6 col-md-6">
                <div className="feature">
                  <div className="icon">
                    <img
                      src="images/delivery.png"
                      alt="Delivery"
                      className="imf-fluid"
                    />
                  </div>
                  <h3>Prompt Delivery</h3>
                  <p>
                    We understand the importance of timely part replacements.
                    Our efficient logistics and shipping ensure that your orders
                    are delivered promptly, minimizing downtime and keeping your
                    operations running smoothly.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{'height': '40%', 'position': 'sticky', 'top': '100px'}}>
            <div className="img-wrap">
              <img
                src="images/why-choose-us-img1.jpg"
                alt="Why Choose"
                className="img-fluid"
                style={{ 'width': '100%', 'height': '100%', objectFit: 'cover' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyChoose;
