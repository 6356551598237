import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductHeroSection from "../components/ProductHeroSection";
import ProductList from "../components/ProductList";

const Product = ()=>{
    return(
        <>
            <Header/>
            <ProductHeroSection />
            <ProductList />
            <Footer/>
        </>
    );
}
export default Product;