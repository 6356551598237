import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../Fragments/metaFragments";

export const ALLUSERSUBCATEGORYLIST = gql`
  query userListSubCategory(
    $offset: Int!
    $limit: Int!
    $query: String
    $sortColumn: String
    $sortOrder: String
    $categoryUuid: String
  ) {
    userListSubCategory(
      input: {
        offset: $offset
        limit: $limit
        query: $query
        sort_column: $sortColumn
        sort_order: $sortOrder
        category_uuid: $categoryUuid
      }
    ) {
      meta {
        ...MetaFragment
      }
      data {
        id
        category_name
        category_uuid
        uuid
        name
        slug
        status
      }
    }
  }
  ${META_FRAGMENT}
`;
