import alt_img from '../assets/alt_image.png';

export const REACT_APP_BASE_URL = 'https://www.api.airwaveindus.in';

export const setAltImage = (e:any)=>{
    const getElementID = e.target.id;
    const getElement:any = document.getElementById(getElementID);
    getElement.src = alt_img
}

export const ScrollTop = ()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
}