const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container relative">
          {/* <!-- <div className="sofa-img">
					<img src="images/sofa.png" alt="Image" className="img-fluid">
				</div> --> */}

          {/* <!-- <div className="row">
					<div className="col-lg-8">
						<div className="subscription-form">
							<h3 className="d-flex align-items-center"><span className="me-1"><img src="images/envelope-outline.svg" alt="Image" className="img-fluid"></span><span>Subscribe to Newsletter</span></h3>

							<form action="#" className="row g-3">
								<div className="col-auto">
									<input type="text" className="form-control" placeholder="Enter your name">
								</div>
								<div className="col-auto">
									<input type="email" className="form-control" placeholder="Enter your email">
								</div>
								<div className="col-auto">
									<button className="btn btn-primary">
										<span className="fa fa-paper-plane"></span>
									</button>
								</div>
							</form>

						</div>
					</div>
				</div> --> */}

          <div className="row gap-5 mb-5">
            <div className="col-lg-4">
              <div className="mb-4 footer-logo-wrap">
                <a href="/">
                  <img src="/images/logo.png" className="footer-logo" alt="Airwave Industrial Solutions" />
                </a>
              </div>
              <p className="mb-4">
                Join us on social media for the latest updates, industry
                insights, and exclusive promotions! Like our page to stay
                connected with airwave industrial solutions. We're your go-to
                source for high-quality screw compressor parts and industrial solutions. Let's stay connected!{" "}
              </p>

              <ul className="list-unstyled custom-social">
                <li>
                  <a href="/">
                    <span className="fa fa-brands fa-facebook-f"></span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span className="fa fa-brands fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span className="fa fa-brands fa-instagram"></span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span className="fa fa-brands fa-linkedin"></span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4">
              <div className="row links-wrap">
                <div className="col-6 col-sm-6 col-md-6">
                  <ul className="list-unstyled">
                    <li>
                      <a href="/#about">About us</a>
                    </li>
                    <li>
                      <a href="/#product">Products</a>
                    </li>
                    <li>
                      <a href="/#blog">Blog</a>
                    </li>
                  </ul>
                </div>

                <div className="col-6 col-sm-6 col-md-6">
                  <ul className="list-unstyled">
                    <li>
                      <a href="/#contact">Contact us</a>
                    </li>
                    <li>
                      <a href="/">Support</a>
                    </li>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="border-top copyright">
            <div className="row pt-4">
              <div className="col-lg-6">
                <p className="mb-2 text-center text-lg-start">
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script>.
                  All Rights Reserved. &mdash; Designed by{" "}
                  <a href="/">P Squre Plus</a> Developed By{" "}
                  <a href="/">Scorptech</a>
                  {/* <!-- License information: https://untree.co/license/ --> */}
                </p>
              </div>

              <div className="col-lg-6 text-center text-lg-end">
                <ul className="list-unstyled d-inline-flex ms-auto">
                  <li className="me-4">
                    <a href="/">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
