/* eslint-disable jsx-a11y/alt-text */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ALLUSERLISTPRODUCT,
  VIEWPRODUCT,
} from "../../framework/Graphql/Query/Product";
import "./OneProductDetails.css";
import Loader from "../Loading/Loader";
import alt_img from "../../assets/alt_image.png";
import { ScrollTop, setAltImage } from "../../constant/constant";

const OneProductDetails = () => {
  const params = useParams();
  const id: any = params.prodUuid;
  const [productDetails, setProductDetails] = useState<any>();
  const [allTopProduct, setAllTopProduct] = useState([]);
  const [GetProductDetails, { loading }] = useLazyQuery(VIEWPRODUCT);
  const [
    GetAllTopProduct,
    { loading: topProductLoading },
  ] = useLazyQuery(ALLUSERLISTPRODUCT);
  const getProduct = () => {
    GetProductDetails({
      variables: {
        uuid: id,
      },
    }).then((res) => {
      setProductDetails(res?.data?.userViewProduct?.data);
    });
    GetAllTopProduct({
      variables: {
        offset: 1,
        limit: 100,
        query: "",
        sortColumn: "id",
        sortOrder: "ASC",
        topProduct: 'YES'
      },
    }).then((res) => {
      setAllTopProduct(res?.data?.userListProduct?.data);
    });
  };
  useEffect(() => {
    getProduct();
  }, [id, productDetails,allTopProduct]);

  useEffect(() => {
    ScrollTop();
  }, []);
  const change = (e: any) => {
    const src = e.target.src;
    const mainElement: any = document.getElementById("main");
    mainElement.src = src;
  };
  const setAltImages = (e: any) => {
    const getElementID = e.target.id;
    const getElement: any = document.getElementById(getElementID);
    if (getElement.src) {
      return;
    }
    getElement.src = alt_img;
  };
  if (loading ||topProductLoading) {
    return <Loader />;
  }
  return (
    <>
      {/* <!-- Product section--> */}
      <section className="py-5">
        <div className="container px-4 px-lg-5 my-5">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-6">
              <div className="main_view">
                <img
                  src={productDetails?.main_img}
                  id="main"
                  alt={productDetails?.part_number}
                  onError={(e) => {
                    setAltImage(e);
                  }}
                />
              </div>
              <div className="col-md-9 justify-content-center">
                <div className="side_view">
                  <img
                    src={productDetails?.main_img}
                    id="main-sub"
                    onClick={(e) => {
                      change(e);
                    }}
                    onError={(e) => {
                      setAltImage(e);
                    }}
                  />
                  {productDetails?.sec_img && (
                    <img
                      src={productDetails?.sec_img}
                      id="sec_img"
                      onClick={(e) => {
                        change(e);
                      }}
                      onError={(e) => {
                        setAltImage(e);
                      }}
                    />
                  )}
                  {productDetails?.thrd_img && (
                    <img
                      src={productDetails?.thrd_img}
                      id="thr_img"
                      onClick={(e) => {
                        change(e);
                      }}
                      onError={(e) => {
                        setAltImage(e);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="small fs-5 mb-1">
                Replacement Parts Of {productDetails?.name}
              </div>
              <h1 className="display-5 fw-bolder">
                {productDetails?.part_number}
              </h1>
              <div className="fs-5 mb-4">
                Price: <span>{productDetails?.price}</span>
              </div>
              <div className="fs-5 mb-2">Product Details</div>
              {/* outer_td, outer_bd, inner_td, inner_bd, no_check_valve, no_pass_valves, thread, height */}
              {productDetails?.outer_td && (
                <p className="fs-6 mb-2">
                  Top Outer Dimension: {productDetails?.outer_td}{" "}
                </p>
              )}
              {productDetails?.outer_bd && (
                <p className="fs-6 mb-2">
                  Bottom Outer Dimension: {productDetails?.outer_bd}{" "}
                </p>
              )}
              {productDetails?.inner_td && (
                <p className="fs-6 mb-2">
                  Top Inner Dimension: {productDetails?.inner_td}{" "}
                </p>
              )}
              {productDetails?.inner_bd && (
                <p className="fs-6 mb-2">
                  Bottom Inner Dimension: {productDetails?.inner_bd}{" "}
                </p>
              )}
              {productDetails?.no_check_valve && (
                <p className="fs-6 mb-2">
                  Number of check valve: {productDetails?.no_check_valve}{" "}
                </p>
              )}
              {productDetails?.no_pass_valves && (
                <p className="fs-6 mb-2">
                  Number of by-pass valves: {productDetails?.no_pass_valves}{" "}
                </p>
              )}
              {productDetails?.thread && (
                <p className="fs-6 mb-2">Thread: {productDetails?.thread} </p>
              )}
              {productDetails?.height && (
                <p className="fs-6 mb-2">Height: {productDetails?.height} </p>
              )}
              <div className="fs-5 mt-5 mb-2">For More Details Contact us</div>
              <div className="col-lg-12">{/* <!-- /.service --> */}</div>

              <div className="col-lg-12">
                <div
                  className="service no-shadow d-flex align-items-start link horizontal d-flex active"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="service-icon color-1 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                    </svg>
                  </div>
                  {/* <!-- /.icon --> */}
                  <div className="service-contents">
                    <a href="mailto:airwave2311@gmail.com">airwave2311@gmail.com</a><br/>
                    <a href="mailto:contact@airwaveindus.in">contact@airwaveindus.in</a>
                  </div>
                  {/* <!-- /.service-contents--> */}
                </div>
                {/* <!-- /.service --> */}
              </div>

              <div className="col-lg-12">
                <div
                  className="service no-shadow align-items-start link horizontal d-flex active"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="service-icon color-1 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                  </div>
                  {/* <!-- /.icon --> */}
                  <div className="service-contents d-flex flex-column">
                    <a href="tel:+919558456773">+91 95584 56773</a>
                    <a href="tel:+919016375702">+91 90163 75702</a>
                  </div>
                  {/* <!-- /.service-contents--> */}
                </div>
                {/* <!-- /.service --> */}
              </div>
              {/* <div className="d-flex">
                            <input className="form-control text-center me-3" id="inputQuantity" type="num" defaultValue="1" style={{"maxWidth": "3rem"}} />
                            <button className="btn btn-outline-dark flex-shrink-0" type="button">
                                <i className="bi-cart-fill me-1"></i>
                                Add to cart
                            </button>
                        </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Related items section--> */}
      <section className="py-5 bg-light">
        <div className="container px-4 px-lg-5 mt-5">
          <h2 className="fw-bolder">Top products</h2>
          <div className="row gx-12 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center product-section">
            {allTopProduct &&
                allTopProduct.length > 0 &&
                allTopProduct.map((item: any) => {
                  return (
                    <div
                      className="col-12 col-md-4 col-lg-3 mb-5"
                      key={item.uuid}
                    >
                      <Link
                        className="product-item"
                        to={`/product-details/${item.uuid}`}
                        onClick={ScrollTop}
                      >
                        <img
                          src={item.main_img}
                          className="img-fluid product-thumbnail"
                          alt={item?.part_number}
                          id="product-list"
                          onError={(e) => {
                            setAltImages(e);
                          }}
                        />
                        <h3 className="product-title">{item.name}</h3>
                        <strong className="product-price">
                          {item.part_number}
                        </strong>

                        <span className="icon-cross">
                          <img
                            src="/images/cross.svg"
                            className="img-fluid"
                            alt="product"
                          />
                        </span>
                      </Link>
                    </div>
                  );
                })}
          </div>
        </div>
      </section>
    </>
  );
};
export default OneProductDetails;
