import React,{ useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { ALLUSERLISTPRODUCT } from "../framework/Graphql/Query/Product";
import { Link } from "react-router-dom";
import alt_img from "../assets/alt_image.png";
import Loader from "./Loading/Loader";

const TopProduct = () => {
  const [allTopProduct, setAllTopProduct] = useState([]);
  const [
    GetAllTopProduct,
    { loading: topProductLoading },
  ] = useLazyQuery(ALLUSERLISTPRODUCT);
  const getProduct = () => {
    GetAllTopProduct({
      variables: {
        offset: 1,
        limit: 100,
        query: "",
        sortColumn: "id",
        sortOrder: "ASC",
        topProduct: 'YES'
      },
    }).then((res) => {
      setAllTopProduct(res?.data?.userListProduct?.data);
    });
  };
  useEffect(() => {
    getProduct();
  }, []);
  const setAltImages = (e: any) => {
    const getElementID = e.target.id;
    const getElement: any = document.getElementById(getElementID);
    if (getElement.src) {
      return;
    }
    getElement.src = alt_img;
  };
  if (topProductLoading) {
    return <Loader />;
  }
  return (
    <div className="product-section pt-0">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 mx-auto text-center">
            <h2 className="section-title">Top Products</h2>
          </div>
        </div>
        <div className="row">
          <>
           {/* <!-- Start Column 1 --> */}
           <div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
            <h2 className="mb-4 section-title">
              Precision Parts for Perfect Performance
            </h2>
            <p className="mb-4">
              Our precision parts are the key to flawless performance. Crafted
              with meticulous attention to detail, they enhance your equipment's
              reliability and efficiency. Elevate your operation with our
              high-quality components for precision and perfect performance.
            </p>
          </div>
          {/* <!-- End Column 1 --> */}

          {/* <!-- Start Column 2 --> */}
          {allTopProduct &&
                allTopProduct.length > 0 &&
                allTopProduct.map((item: any) => ( 
                <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
                   <Link
                        className="product-item"
                        to={`/product-details/${item.uuid}`}
                      >
                        <img
                          src={item.main_img}
                          className="img-fluid product-thumbnail"
                          alt={item?.part_number}
                          id="product-list"
                          onError={(e) => {
                            setAltImages(e);
                          }}
                        />
                        <h3 className="product-title">{item.name}</h3>
                        <strong className="product-price">
                          {item.part_number}
                        </strong>

                        <span className="icon-cross">
                          <img
                            src="/images/cross.svg"
                            className="img-fluid"
                            alt="product"
                          />
                        </span>
                      </Link>
                </div>
                ))}
         
          {/* <!-- End Column 2 --> */}
          </>
        </div>
      </div>
    </div>
  );
};
export default TopProduct;
