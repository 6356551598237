import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { GETARTICLECONTENT } from "../constant/Blog";
import { useEffect } from "react";
import { ScrollTop } from "../constant/constant";

const BlogDetails = () => {
  const params = useParams();
  const id: any = params.blog;
  const find_data = GETARTICLECONTENT(id);
  useEffect(() => {
    ScrollTop();
  }, []);
  return (
    <>
      {/* <!-- Start Hero Section --> */}
      <Header />
      <div className="hero">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="intro-excerpt">
                <h1>Article</h1>
                <p className="mb-4">
                  At Airwave Industrial Solutions, we understand that every
                  industrial operation is unique, and that's why we offer an
                  extensive range of high-quality screw compressor parts to meet
                  your specific needs. Whether you're looking for routine
                  maintenance components or critical replacement parts, we've
                  got you covered.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="hero-img-wrap">
                <img
                  src="/images/new-sales.png"
                  className="img-fluid"
                  alt="sales"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="untree_co-section product-section before-footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Trending Tittle --> */}
              <div className="about-right">
                <div className="about-img pb-4">
                  <img src={find_data?.image} alt="" />
                </div>
                <div className="heading-news mb-30 pt-30">
                  <h3
                    dangerouslySetInnerHTML={{ __html: find_data?.title }}
                  ></h3>
                </div>
                <div className="about-prea">
                  <p
                    dangerouslySetInnerHTML={{ __html: find_data?.details }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default BlogDetails;
